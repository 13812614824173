import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { getRequest } from "../../api-service";

const CACHE_KEY = 'silosecure_data';
const CHECK_INTERVAL = 5 * 60 * 1000; // Check for updates every 5 minutes

const NewsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getRequest(`public/get-silosecure`, { is_request: 1 });
      if (res.status && res.Silosecures) {
        saveToCache(res);
        updateDataFromCache(res);
      } else {
        console.error('Unexpected response structure:', res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const checkForUpdates = useCallback(async () => {
    try {
      const res = await getRequest(`public/get-silosecure`, { is_request: 1 });
      if (res.status && res.Silosecures) {
        const cachedData = getFromCache();
        if (!cachedData || JSON.stringify(res) !== JSON.stringify(cachedData)) {
          saveToCache(res);
          updateDataFromCache(res);
        }
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  }, []);

  useEffect(() => {
    // Clear cache on page load
    localStorage.removeItem(CACHE_KEY);

    const cachedData = getFromCache();
    if (cachedData) {
      updateDataFromCache(cachedData);
    } else {
      fetchData();
    }

    // Set up periodic checks for new data
    const intervalId = setInterval(checkForUpdates, CHECK_INTERVAL);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchData, checkForUpdates]);

  const saveToCache = (data) => {
    localStorage.setItem(CACHE_KEY, JSON.stringify(data));
  };

  const getFromCache = () => {
    const itemStr = localStorage.getItem(CACHE_KEY);
    return itemStr ? JSON.parse(itemStr) : null;
  };

  const updateDataFromCache = useCallback((cachedData) => {
    const { Silosecures } = cachedData;
    setTotalItems(Silosecures.length);
    paginateAndFilterData(Silosecures, currentPage, itemsPerPage, searchKeyword);
    setLoading(false);
  }, [currentPage, itemsPerPage, searchKeyword]);

  const paginateAndFilterData = useCallback((allData, page, limit, keyword) => {
    let filteredData = allData;

    if (keyword) {
      const lowerKeyword = keyword.toLowerCase();
      filteredData = allData.filter(item =>
        item.full_name.toLowerCase().includes(lowerKeyword) ||
        item.email.toLowerCase().includes(lowerKeyword)
      );
    }

    setTotalItems(filteredData.length);
    setTotalPages(Math.ceil(filteredData.length / limit));

    const startIndex = (page - 1) * limit;
    const paginatedData = filteredData.slice(startIndex, startIndex + limit);

    setData(paginatedData);
    setCurrentPage(page);
  }, []);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
    const cachedData = getFromCache();
    if (cachedData) {
      paginateAndFilterData(cachedData.Silosecures, 1, itemsPerPage, keyword);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    const cachedData = getFromCache();
    if (cachedData) {
      paginateAndFilterData(cachedData.Silosecures, 1, newItemsPerPage, searchKeyword);
    }
  };

  const paginate = (pageNumber) => {
    const cachedData = getFromCache();
    if (cachedData) {
      paginateAndFilterData(cachedData.Silosecures, pageNumber, itemsPerPage, searchKeyword);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div>
      <Card className="card-preview border-0" style={{ padding: "15px" }}>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div style={{ width: "20%", margin: "10px 0" }}>
            <Input
              type="search"
              placeholder="Search by title"
              value={searchKeyword}
              onChange={handleSearchInputChange}
            />
          </div>

          <div className="d-flex">
            <Label className="me-2">Show</Label>
            <Input
              type="select"
              name="select"
              id="itemsPerPageSelect"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "fit-content", height: "fit-content" }}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Input>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Table
            className="all-table"
            striped
            style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }}>
            <thead>
              <tr>
                <th className="border-end">Sr. No.</th>
                <th className="border-end">Name</th>
                <th className="border-end">Email</th>
                <th className="border-end">Phone</th>
                <th className="border-end">Date</th>
                <th className="border-end">Time</th>
                <th className="border-end">Message</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7">
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner type="grow" color="danger" />
                    </div>
                  </td>
                </tr>
              ) : data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={item?.id}>
                    <td className="border-end">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td className="border-end text-start">{item?.full_name}</td>
                    <td className="border-end text-start">{item?.email}</td>
                    <td className="border-end">{item?.phone}</td>
                    <td className="border-end">{formatDate(item.date)}</td>
                    <td className="border-end">{item?.time}</td>
                    <td className="border-end text-start">{item?.message}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between mt-2">
          <Pagination>
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink first onClick={() => paginate(1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
            </PaginationItem>
            {Array.from({ length: totalPages }).map((_, index) => (
              <PaginationItem key={index} active={index + 1 === currentPage}>
                <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink next onClick={() => paginate(currentPage + 1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink last onClick={() => paginate(totalPages)} />
            </PaginationItem>
          </Pagination>
          <div>
            Page {currentPage} of {totalPages} (Total items: {totalItems})
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewsList;