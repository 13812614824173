import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { postRequest } from "../../api-service";

const NewsList = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedNews, setSelectedNews] = useState(null);
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    getNewsData();
  }, [currentPage, itemsPerPage, searchKeyword]);

  const toggleModal = () => setModal(!modal);

  const getNewsData = async () => {
    setLoading(true);
    try {
      const payload = {
        page: currentPage,
        limit: itemsPerPage,
        search: searchKeyword
      };
      const res = await postRequest(
        `ContactUs/get-contact-data`,
        payload
      );

      if (res.status && res.data) {
        setNewsData(res.data.contact);
        setCurrentPage(res.data.page);
        setItemsPerPage(res.data.limit);
        setTotalPages(Math.ceil(res.data.count / res.data.limit));
      } else {
        console.error("Error in API response:", res.message);
      }
    } catch (error) {
      console.error("Error fetching news data:", error);
    } finally {
      setLoading(false);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    let sortableItems = [...newsData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue == null) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (bValue == null) return sortConfig.direction === 'ascending' ? 1 : -1;

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else if (aValue instanceof Date && bValue instanceof Date) {
          return sortConfig.direction === 'ascending'
            ? aValue.getTime() - bValue.getTime()
            : bValue.getTime() - aValue.getTime();
        } else {
          return sortConfig.direction === 'ascending'
            ? aValue - bValue
            : bValue - aValue;
        }
      });
    }
    return sortableItems;
  }, [newsData, sortConfig]);

  const handleEdit = (news) => {
    setSelectedNews(news);
    localStorage.setItem("newsIdForEdit", news);
    navigate("/admin-manage-coinexchange/editNews");
  };

  const handleDelete = (newsId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = { news_id: newsId };
        postRequest("/delete-news", payload).then(
          () =>
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }),
          getNewsData()
        );
      }
    });
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });

    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
  };

  return (
    <div>
      <Card className="card-preview border-0">
        <div className="d-flex justify-content-between">
          <Input
            type="search"
            placeholder="Search by title"
            value={searchKeyword}
            onChange={handleSearchInputChange}
            style={{ width: "20%", margin: "10px 0" }}
          />
          <div className="d-flex align-items-center">
            <Label className="me-2 mb-0">Show</Label>
            <Input
              type="select"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "fit-content", height: "fit-content" }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Input>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Table
            className="all-table"
            striped
            style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }}
          >
            <thead>
              <tr>
                <th className="border-end">Sr. No.</th>
                <th className="border-end">
                  Name
                  <Button color="black" className="p-0" onClick={() => requestSort('first_name')}>
                    {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                  </Button>
                </th>
                <th className="border-end">
                  Email
                  <Button color="black" className="p-0" onClick={() => requestSort('email')}>
                    {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                  </Button>
                </th>
                <th className="border-end">Phone Number</th>
                <th className="border-end">Message</th>
                <th className="border-end">
                  Date
                  <Button color="black" className="py-0 px-1" onClick={() => requestSort('updated_at')}>
                    {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <Spinner style={{ margin: "0 auto" }} type="grow" color="danger">Loading...</Spinner>
                  </td>
                </tr>
              ) : (
                sortedData.map((news, index) => (
                  <tr key={news.id}>
                    <td className="border-end">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td className="border-end" style={{ textAlign: "left", maxWidth: "200px" }}>
                      {`${news.first_name || ""} ${news.last_name || ""}`}
                    </td>
                    <td className="border-end" style={{ textAlign: "left", maxWidth: "200px" }}>
                      <a href={`mailto:${news.email}`}>{news.email}</a>
                    </td>
                    <td className="border-end text-center" style={{ maxWidth: "100px" }}>
                      <a href={`tel:+${news.phone}`}>{news.phone}</a>
                    </td>
                    <td className="border-end" style={{ textAlign: "left", maxWidth: "250px" }}>
                      {news.note || "-"}
                    </td>
                    <td className="border-end text-center" style={{ maxWidth: "200px" }}>
                      {formatDate(news.updated_at)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between mt-2">
          <Pagination>
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink first onClick={() => paginate(1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
            </PaginationItem>
            {Array.from({ length: totalPages }).map((_, index) => (
              <PaginationItem key={index} active={index + 1 === currentPage}>
                <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink next onClick={() => paginate(currentPage + 1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage >= totalPages}>
              <PaginationLink last onClick={() => paginate(totalPages)} />
            </PaginationItem>
          </Pagination>
        </div>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit News</ModalHeader>
        <ModalBody>
          {selectedNews && <p>Edit form for {selectedNews.title}</p>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          <Button color="primary" onClick={toggleModal}>Save</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewsList;
